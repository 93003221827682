import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c(VRow,[_c(VCol,{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('h3',[_vm._v("Unit")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('hr')])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('label',[_vm._v("Description")]),_c(VTextField,{attrs:{"rules":[_vm.validations.required],"single-line":"","outlined":"","dense":""},model:{value:(_vm.unityRequest.description),callback:function ($$v) {_vm.$set(_vm.unityRequest, "description", $$v)},expression:"unityRequest.description"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('label',[_vm._v("Symbol")]),_c(VTextField,{attrs:{"rules":[_vm.validations.required],"single-line":"","outlined":"","dense":""},model:{value:(_vm.unityRequest.symbol),callback:function ($$v) {_vm.$set(_vm.unityRequest, "symbol", $$v)},expression:"unityRequest.symbol"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('label',[_vm._v("Field Type")]),_c(VCombobox,{attrs:{"items":_vm.listFieldType,"rules":[_vm.validations.required],"item-text":"description","item-value":"id","clearable":"","outlined":"","dense":""},model:{value:(_vm.fieldTypeSelected),callback:function ($$v) {_vm.fieldTypeSelected=$$v},expression:"fieldTypeSelected"}})],1)],1),_c('ActionButtons',{attrs:{"request":_vm.unityRequest,"loading":_vm.loading,"showButtons":{
                cancel: true,
                save: true
            }},on:{"cancel":_vm.cancel,"save":_vm.save}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }